import React, { useState } from 'react';
import { token, IconMenu, Box, IconClose } from '@zillow/constellation';

import styled from 'styled-components';
import MenuItem from './MenuItem';

const MenuItems = styled.ol`
    display: flex;
    align-content: stretch;
    align-items: center;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: 1280px;
    /* Mobile */
    @media (max-width: 889px) {
        padding: 0;
        display: ${(props) => (props.menuOpen ? 'block' : 'none')};
        position: absolute;
        background-color: ${token('colors.white')};
        z-index: ${token('zIndices.popover')};
        width: 100%;
    }
`;

const Menu = ({ menu }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const ToggleIcon = isMenuOpen ? IconClose : IconMenu;
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    left: '15px',
                    top: '19px',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '$zIndices.popover',
                    backgroundColor: 'white',
                    '@media (min-width: 889px)': {
                        display: 'none',
                    },
                }}
            >
                <ToggleIcon
                    size={isMenuOpen ? 'xs' : 'sm'}
                    fontColor="brand"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
            </Box>
            <Box
                sx={{
                    borderBottom: 'solid 1px $colors.borderLight',
                    backgroundColor: '$colors.white',
                    '@media (max-width: 890px)': {
                        borderBottom: 'none',
                    },
                }}
            >
                <MenuItems menuOpen={isMenuOpen}>
                    {menu
                        .filter((menuItem) => !menuItem.parent)
                        .map((menuItem) => {
                            return (
                                <MenuItem
                                    key={menuItem.id}
                                    menuItem={menuItem}
                                    menu={menu}
                                />
                            );
                        })}

                    {/* Go to Zillow Link for mobile */}
                    <MenuItem
                        key="home"
                        mobileOnly
                        menuItem={{
                            id: 'home',
                            title: { rendered: 'Go to Zillow' },
                            url: '/',
                        }}
                        menu={menu}
                    />
                </MenuItems>
            </Box>
        </>
    );
};

export default Menu;
