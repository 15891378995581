import React from 'react';
import styled from 'styled-components';
import Menu from './Menu';

const SubnavWrapper = styled.div``;

const Subnav = ({ router, menu }) => {
    if (!menu?.length) {
        return null;
    }
    return (
        <SubnavWrapper>
            <Menu router={router} menu={menu} />
        </SubnavWrapper>
    );
};

export default Subnav;
