import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { debounce } from 'lodash';

import {
    token,
    IconButton,
    IconChevronDown,
    IconChevronUp,
} from '@zillow/constellation';
import styled from 'styled-components';
import { event } from '@zillow/universal-analytics';
import { getCmsInfoForPage, usePageProps } from '@zillow/zrs-cms';

import Submenu from './Submenu';

const MenuItemContainer = styled.li`
    display: flex;
    align-self: stretch;
    padding: 0;
    position: relative;
    align-items: center;
    flex-wrap: wrap;

    /* Desktop */
    @media (max-width: 889px) {
        border-bottom: solid 1px ${token('colors.borderLight')};
    }

    ${(props) =>
        props.mobileOnly &&
        `
        @media (min-width: 890px) {
            display: none;
        }
    `}
`;

const MenuLink = styled.a`
    display: flex;
    flex: 1;
    position: relative;
    height: 55px;
    align-items: center;
    font-family: ${token('fonts.sansSerif')};
    font-size: ${token('fontSizes.bodySmall')}px;
    line-height: 40px;
    padding: 0 ${token('spacing.sm')}px;
    color: ${token('colors.textDark')};
    text-decoration: none;
    white-space: nowrap;
    &.current {
        color: ${token('colors.brand')} !important;
        &:after {
            content: '';
            border-top: solid 3px ${token('colors.brand')};
            position: absolute;
            left: ${token('spacing.sm')}px;
            right: ${token('spacing.sm')}px;
            bottom: 0;
            z-index: 10;
            width: calc(100% - ${token('spacing.sm') * 2}px);
        }
    }
    &:visited {
        color: ${token('colors.textDark')};
    }
    &:hover {
        color: ${token('colors.brand')};
    }
    /* Mobile */
    @media (max-width: 889px) {
        font-size: ${token('fontSizes.body')}px;
        &.current {
            &:after {
                display: none;
            }
        }
    }
    /* Tablet */
    @media (max-width: 1149px) {
        padding: 0 ${token('spacing.sm')}px;
    }
`;

const StyledIconButton = styled(IconButton)`
    @media (max-width: 889px) {
        width: 66px;
        height: 55px;
        &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 0;
            width: 1px;
            height: 25px;
            background-color: ${token('colors.borderLight')};
        }
    }
`;

const MenuItem = ({ menu, menuItem, mobileOnly }) => {
    const router = useRouter();
    const pageProps = usePageProps();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const onSubmenuOpen = () => {
        if (!hasChildren) {
            return;
        }

        setIsSubmenuOpen(true);
        event({
            // https://dataportal.zillowgroup.net/event-registry/5030
            envelope: {
                event_type_id: '5030',
                event_type_version_id: '1',
                event_template_id: '297',
                event_template_version_id: '1',
                // Master ID: 2284.2.126.1
            },
            clickstream_trigger: {
                trigger_type_nm: 'interaction',
                trigger_location_nm: 'cms',
                trigger_source_nm: 'cms_subnav',
                trigger_object_nm: 'no_trigger_object',
            },
            semantic: {
                semantic_event_nm: 'cms_open',
            },
            cms_info: {
                block_id: menuItem?.attr_id || '',
                block_type_cd: 'subnav',
                block_displayed_txt: menuItem?.title?.rendered,
                ...getCmsInfoForPage(pageProps),
            },
        });
    };

    const onSubmenuClose = () => {
        if (!hasChildren) {
            return;
        }
        setIsSubmenuOpen(false);
        event({
            // https://dataportal.zillowgroup.net/event-registry/2051
            envelope: {
                event_type_id: '2051',
                event_type_version_id: '1',
                event_template_id: '126',
                event_template_version_id: '1',
                // Master ID: 2284.2.126.1
            },
            clickstream_trigger: {
                trigger_type_nm: 'interaction',
                trigger_location_nm: 'cms',
                trigger_source_nm: 'cms_subnav',
                trigger_object_nm: 'no_trigger_object',
            },
            semantic: {
                semantic_event_nm: 'cms_close',
            },
            cms_info: {
                block_id: menuItem?.attr_id || '',
                block_type_cd: 'subnav',
                block_displayed_txt: menuItem?.title?.rendered,
                ...getCmsInfoForPage(pageProps),
            },
        });
    };

    useEffect(() => {
        const onResize = () => {
            setIsMobile(window.innerWidth < 890);
        };
        const debouncedOnResize = debounce(onResize, 100);

        setIsMobile(window.innerWidth < 890);
        // eslint-disable-next-line no-undef
        window.addEventListener('resize', debouncedOnResize);
        window.addEventListener('orientationchange', debouncedOnResize);

        return () => {
            // eslint-disable-next-line no-undef
            window.removeEventListener('resize', debouncedOnResize);
            window.removeEventListener('orientationchange', debouncedOnResize);
        };
    }, []);

    let path;
    try {
        const url = new URL(menuItem.url);
        path = url.pathname;
    } catch (error) {
        console.warn(`Failed to parse URL: ${menuItem.url}`);

        if (menuItem.url.startsWith('#')) {
            path = router.pathname;
        } else {
            path = '';
        }
    }

    const hasChildren = menu.some(
        (item) => item.parent.toString() === menuItem.id
    );

    // Format the slug from the router to match the path
    let { slug = ['/'] } = router.query;
    slug = slug.join('/');
    slug = `/${slug}/`;

    return (
        <MenuItemContainer
            mobileOnly={mobileOnly}
            onMouseEnter={() => {
                if (!isMobile) {
                    // Trigger an event when the menu item is hovered.
                    // https://dataportal.zillowgroup.net/event-registry/2049
                    event({
                        envelope: {
                            event_type_id: '2049',
                            event_type_version_id: '1',
                            event_template_id: '126',
                            event_template_version_id: '1',
                            // Master ID: 2284.2.126.1
                        },
                        clickstream_trigger: {
                            trigger_type_nm: 'interaction',
                            trigger_location_nm: 'cms',
                            trigger_source_nm: 'cms_subnav',
                            trigger_object_nm: 'no_trigger_object',
                        },
                        semantic: {
                            semantic_event_nm: 'cms_hover',
                        },
                        cms_info: {
                            block_id: menuItem?.attr_id || '',
                            block_type_cd: 'subnav',
                            block_displayed_txt: menuItem?.title?.rendered,
                            ...getCmsInfoForPage(pageProps),
                        },
                    });
                    onSubmenuOpen();
                }
            }}
            onMouseLeave={() => {
                if (!isMobile) {
                    onSubmenuClose();
                }
            }}
        >
            <MenuLink
                href={menuItem.url}
                title={menuItem?.title?.rendered}
                target={menuItem.target}
                className={path === slug ? 'current' : ''}
                id={menuItem.attr_id}
                onClick={(e) => {
                    event({
                        // https://dataportal.zillowgroup.net/event-registry/2048
                        envelope: {
                            event_type_id: '2048',
                            event_type_version_id: '1',
                            event_template_id: '126',
                            event_template_version_id: '1',
                            // Master ID: 2284.2.126.1
                        },
                        clickstream_trigger: {
                            trigger_type_nm: 'interaction',
                            trigger_location_nm: 'cms',
                            trigger_source_nm: 'cms_anchor',
                            trigger_object_nm: 'no_trigger_object',
                        },
                        semantic: {
                            semantic_event_nm: 'cms_click',
                        },
                        cms_info: {
                            block_id: e.target.id || '',
                            block_type_cd: 'subnav',
                            block_displayed_txt: e.target.textContent,
                            block_target_url: menuItem.url,
                            block_target_window_txt: menuItem.target || '',
                            ...getCmsInfoForPage(pageProps),
                        },
                    });
                }}
            >
                {menuItem?.title?.rendered}
            </MenuLink>
            {hasChildren && (
                <StyledIconButton
                    bare
                    size="sm"
                    icon={
                        isSubmenuOpen ? <IconChevronUp /> : <IconChevronDown />
                    }
                    buttonType="secondary"
                    onClick={() => {
                        if (isSubmenuOpen) {
                            onSubmenuClose();
                        } else {
                            onSubmenuOpen();
                        }
                    }}
                />
            )}
            <Submenu
                menu={menu}
                parentId={menuItem.id}
                isOpen={isSubmenuOpen}
            />
        </MenuItemContainer>
    );
};

export default MenuItem;
