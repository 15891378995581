/* eslint-disable @next/next/no-sync-scripts */
import parse from 'html-react-parser';
import Head from 'next/head';
import { withRouter } from 'next/router';
import React from 'react';
import { NEXT_PUBLIC_BEDROCK_STATIC_DOMAIN } from '../../server/config';
import OptimizelyScripts from '../components/OptimizelyScripts';
import GlobalStyle from './GlobalStyle';
import PreviewModeAlert from './PreviewModeAlert';
import Subnav from './Subnav';

const Layout = ({
    router,
    head,
    menu,
    preview,
    children,
    NavComponent = Subnav,
}) => (
    <React.Fragment>
        <Head>
            {head && parse(head)}
            {/* No index for previews */}
            {preview && <meta name="robots" content="noindex" />}

            {/* preconnect to bedrock static domain */}
            {NEXT_PUBLIC_BEDROCK_STATIC_DOMAIN && (
                <link
                    rel="preconnect"
                    href={`https://${NEXT_PUBLIC_BEDROCK_STATIC_DOMAIN}`}
                />
            )}
        </Head>
        <OptimizelyScripts />
        <GlobalStyle />
        <NavComponent router={router} menu={menu} head={head} />
        {preview && <PreviewModeAlert />}
        {children}
    </React.Fragment>
);

export default withRouter(Layout);
