import React from 'react';
import {
    BlockList,
    initializeApollo,
    getPost,
    getBlocksData,
} from '@zillow/cms-blocks';

import { REVALIDATION_INTERVAL } from '../../server/config';

import Layout from '../components/Layout';
import { shouldUseVisualRefreshTheme } from '../util/document';

function Page({ post: { head, menu, blocks, footer } }) {
    return (
        <Layout head={head} menu={menu}>
            <BlockList blocks={blocks} />
            {footer && (
                <BlockList blocks={JSON.parse(footer?.blocks || '[]')} />
            )}
        </Layout>
    );
}

export async function getStaticProps({ params }) {
    // If no slug is provided, default to / to grab the front page
    const { slug = ['/'] } = params;

    initializeApollo();

    const { post } = await getPost({
        variables: {
            id: slug.join('/'),
            idType: 'URI',
            postType: 'PAGE',
        },
        fetchPolicy: 'no-cache',
    });

    // Check for post status.
    if (post?.status !== 'publish') {
        return {
            notFound: true,
        };
    }

    const blocks = await getBlocksData(JSON.parse(post?.blocks || '[]'));

    return {
        props: {
            post: {
                ...post,
                blocks,
            },
            useVisualRefreshTheme: shouldUseVisualRefreshTheme(slug.join('/')),
        },
        revalidate: REVALIDATION_INTERVAL,
    };
}

// TODO: enable if we figure out PFS client side rendering
export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

export default Page;
