import React from 'react';
import { token } from '@zillow/constellation';
import styled from 'styled-components';

const SubmenuItems = styled.ul`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    list-style: none;
    background-color: ${token('colors.backgroundLight')};
    padding: 0;
    flex: 1 1 100%;

    // Desktop
    @media (min-width: 890px) {
        background-color: ${token('colors.white')};

        border-right: 1px solid ${token('colors.borderLight')};
        border-left: 1px solid ${token('colors.borderLight')};
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 220px;
        z-index: 9;

        &:hover {
            display: block;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: solid 1px ${token('colors.white')};
        }
    }
`;

const SubmenuItem = styled.li`
    padding: 0;

    // Desktop
    @media (min-width: 890px) {
        border-bottom: solid 1px ${token('colors.borderLight')};
    }
`;

const SubmenuLink = styled.a`
    display: flex;
    height: 50px;
    align-items: center;
    flex: 1 1 100%;
    font-size: ${token('fontSizes.bodySmall')}px;
    font-family: ${token('fonts.sansSerif')};
    padding: ${token('spacing.xs')}px ${token('spacing.sm')}px;
    color: ${token('colors.brand')};
    text-decoration: none;
    &:visited {
        color: ${token('colors.brand')};
    }
    &:hover {
        color: ${token('colors.textDark')};
        text-decoration: underline;
    }
`;

const Submenu = ({ menu, parentId, isOpen }) => {
    const submenuItems = menu.filter(
        (menuItem) => menuItem.parent.toString() === parentId
    );
    if (!submenuItems.length) {
        return null;
    }

    return (
        <SubmenuItems className="subnav-menu-submenu" isOpen={isOpen}>
            {submenuItems.map((menuItem) => (
                <SubmenuItem key={menuItem.id}>
                    <SubmenuLink
                        href={menuItem.url}
                        title={menuItem?.title?.rendered}
                        target={menuItem.target}
                        id={menuItem.attr_id}
                    >
                        {menuItem?.title?.rendered}
                    </SubmenuLink>
                </SubmenuItem>
            ))}
        </SubmenuItems>
    );
};

export default Submenu;
